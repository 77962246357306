import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/kohvieri_story_large_featured_image.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(
                    process.env.SIGNUP_URL,
                  );
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const NailStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO title="How Kohvieri Coffee Shop manage young workers better with Camelo"></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30 pb-lg-15">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How Kohvieri Coffee Shop manage young workers better with
                    Camelo
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section bg-image py-28 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
          `}
        />

        <div className="pb-2 pb-lg-9 pt-13 pt-lg-24 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    <Link to="https://kohvieri.ee/et/" target="__blank">
                      {`Kohvieri `}
                    </Link>
                    is a charming family-owned coffee and tea shop located in
                    the old town of Tallinn. They have a large selection of
                    high-quality, exclusive coffee and tea products available.
                    At first, they had difficulties with manual scheduling,
                    especially when they had to manage a young workforce. Their
                    transition to digital software—Camelo—has marked a
                    significant improvement in how they schedule and track
                    attendance for their young workers.
                  </p>
                  <h4 className="pt-8 pb-4">Born from a dream</h4>
                  <p className="gr-text-8 text-black">
                    Kohvieri was born from a heartfelt dream — a dream that
                    belonged to the manager’s mother.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “It’s a family business and it was always my mom’s dream
                      to have a little coffee and tea shop, so we started it,”
                      Kohvieri’s manager shares.
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    Anyone interested in coffee or tea can buy exclusive
                    products from Kohvieri. The manager says,
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “Our shop is so special that some businesses that we buy
                      from won’t allow other shops in the country to sell their
                      stuff but only us.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    The help from supportive friends
                  </h4>
                  <p className="gr-text-8 text-black">
                    Like many businesses, Kohvieri faces their fair share of
                    challenges. Finding reliable workers, especially among young
                    people and students, was a common problem. Thankfully, they
                    had a network of supportive friends who could lend a helping
                    hand when needed.
                  </p>

                  <h4 className="pt-8 pb-4">
                    Scheduling was tedious, so they went digital
                  </h4>
                  <p className="gr-text-8 text-black">
                    At first, the coffee shop relied on paper and pen to make
                    schedules. They had to gather all the workers to make a
                    schedule that works for everyone, a tedious and messy
                    process.
                  </p>
                  <p className="gr-text-8 text-black">
                    So, Kohvieri’s manager looked for a scheduling app. And she
                    found Camelo.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “It was pretty easy to use”, she says, “we kept using it
                      because IT (technology) improves every day and since we
                      have young workers, they prefer having everything on their
                      phone than on a calendar on the wall.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    Making sure people work the shifts they want & show up at
                    work
                  </h4>
                  <p className="gr-text-8 text-black">
                    One feature that the team at Kohvieri really liked is
                    clocking in and out with the app using location. When
                    employees start their work shift by clocking in, Camelo
                    records their location. This helps the manager or supervisor
                    confirm if employees are at the correct work sites.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “We really liked the clocking in method where it (Camelo)
                      tracks your location. So we know no one is just turning it
                      on but not coming to work.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    The manager also likes the open shifts feature and the shift
                    claims feature on Camelo. Open shifts allow employees to
                    pick the shifts they want, and shift claims make sure
                    employees only take the shifts they can actually work.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “With Camelo, I can make an open shift for employees to
                      choose who wants to work at that time and also they can
                      reject the shifts when it doesn’t go with their schedule.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">Getting the support they need</h4>
                  <p className="gr-text-8 text-black">
                    But it wasn’t just the features that convinced Kohvieri; it
                    was also the support team at Camelo. The team tries to be
                    responsive, helpful, and dedicated to finding solutions to
                    users’ difficulties.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “What do you like most about Camelo?”
                    </p>
                  </blockquote>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “I absolutely love the support team in Camelo. If I have a
                      question they help immediately, and if I have a problem
                      with anything then they are supportive and trying to find
                      a solution for both sides.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    Helping businesses and the environment
                  </h4>
                  <p className="gr-text-8 text-black">
                    Camelo didn’t just improve the operations of Kohvieri; the
                    app made the owners’ lives easier. It saved them time and
                    contributed to saving the environment by removing the use of
                    paper calendars.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Camelo has definitely saved time and also saved nature
                      with us not using paper calendars anymore.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    In the end, when asked if they would recommend Camelo to
                    others, the answer of Kohvieri’s management was a resounding
                    “Yes”. Camelo’s flexibility and personalization options made
                    it a perfect fit for their unique needs.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Yes, I would recommend Camelo to others because you can
                      personalize it to your preference so it fits for
                      everybody.”
                    </p>
                  </blockquote>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default NailStory;
